import { useLastLocation } from 'react-router-last-location';

import Analytics, { GAClientUserTraits } from './Analytics';
import { AnalyticsContextProviderOptions, AnalyticsContext } from './AnalyticsContext';

const AnalyticsProvider = ({ children, history }: AnalyticsContextProviderOptions): JSX.Element => {
  const lastLocation = useLastLocation();

  Analytics.load(history);

  /* istanbul ignore next: no need to cover this proxy */
  const identify = async (ga4Traits?: GAClientUserTraits): Promise<void> => {
    return Analytics.identify(ga4Traits);
  };

  /* istanbul ignore next: no need to cover this proxy */
  const page = async (name: string, properties?: any): Promise<void> => {
    return Analytics.page(lastLocation, name, properties);
  };

  /* istanbul ignore next: no need to cover this proxy */
  const track = async (event: string, ga4Traits?: any): Promise<void> => {
    return Analytics.track(lastLocation, event, ga4Traits);
  };

  return (
    <AnalyticsContext.Provider
      value={{
        identify,
        page,
        track,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsProvider;
