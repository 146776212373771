import { ReactElement } from 'react';
import { History } from 'history';
import { LastLocationType } from 'react-router-last-location';
import GA4Client, { GAClientUserTraits } from './GA4Client';

export type { GAClientUserTraits };

declare global {
  interface Window {
    ga4Client: GA4Client;
  }
}

export interface IAnalyticsPage {
  readonly pageName: string;
  readonly pageProperties?: any;
  children?: ReactElement;
}

class Analytics {
  static load(history?: History): void {
    window.ga4Client = new GA4Client(history);
  }

  static async identify(traits?: GAClientUserTraits): Promise<void> {
    await window.ga4Client?.identify(traits);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static async page(lastLocation: LastLocationType | null, name: string, properties?: any): Promise<void> {
    await window.ga4Client?.page(lastLocation, name, properties);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static async track(lastLocation: LastLocationType | null, event: string, traits?: any): Promise<void> {
    await window.ga4Client?.track(lastLocation, event, traits);
  }
}

export default Analytics;
